import React from "react";
import axios from "axios";
import InventoryUploadScreen from "../../../../../components/Screen/Inventory/InventoryUploadScreen/InventoryUploadScreen";
class ImportLogsMenu extends React.Component {
    constructor(props) {
        super(props);
        this.onDismiss = () => {
            console.log("close!");
            //this.props.handleClose();
        };
        this.handleUpload = async (fileList) => {
            const formData = new FormData();
            const handle = this;
            for (let i = 0; i < fileList.length; i++) {
                formData.append("file", fileList.item(i));
            }
            axios({
                method: "POST",
                url: "/sl3/rest/orderLogsUpload",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(function (response) {
                //handle success
                handle.setState({ isImportOk: "success" });
                console.log("success");
                console.log(response);
            })
                .catch(function (response) {
                //handle error
                handle.setState({ isImportOk: "failure" });
                console.log("error");
                console.log(response);
            });
        };
        this.renderResultMessage = () => {
            switch (this.state.isImportOk) {
                case "unsent":
                    return null;
                case "failure":
                    return React.createElement("div", null, "Importing failed, please check that all orders are in the system");
                case "success":
                    return React.createElement("div", null, "Import succeeded");
            }
        };
        this.state = { isImportOk: "unsent" };
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(InventoryUploadScreen, { data: {}, actions: { onDismiss: this.onDismiss, handleUpload: this.handleUpload } }),
            this.renderResultMessage()));
    }
}
export default ImportLogsMenu;
