import axios from "axios";
import { SLRestEndpoint } from "../types";
export var Routing;
(function (Routing) {
    Routing.RoutingServiceName = "routing";
    let RoutingEndpointName;
    (function (RoutingEndpointName) {
        RoutingEndpointName["JSON"] = "json";
    })(RoutingEndpointName = Routing.RoutingEndpointName || (Routing.RoutingEndpointName = {}));
    Routing.getMapRoutingData = (req) => {
        return axios({
            method: "POST",
            url: generateURL(RoutingEndpointName.JSON),
            data: req
        }).then(rsp => {
            const serverMapRoutingData = (rsp === null || rsp === void 0 ? void 0 : rsp.data) || null;
            if (!serverMapRoutingData) {
                return null;
            }
            const mapRoutingData = {
                id: req.id,
                type: req.type,
                start: serverMapRoutingData.start,
                end: serverMapRoutingData.end,
                wayPoints: serverMapRoutingData.way_points.map(wayPoint => ({
                    lat: wayPoint.lat,
                    lon: wayPoint.lon,
                    index: wayPoint.index,
                    orderId: wayPoint.order_id,
                    orderNumber: wayPoint.order_number,
                    stateId: wayPoint.state_id,
                    emergency: wayPoint.emergency
                })),
                trackPoints: serverMapRoutingData.track_points.map(trackPoint => ({
                    lat: trackPoint.lat,
                    lon: trackPoint.lon
                })),
                instructions: serverMapRoutingData.instructions.map(instruction => ({
                    distance: instruction.distance,
                    sign: instruction.sign,
                    interval: instruction.interval,
                    text: instruction.text,
                    time: instruction.time,
                    streetName: instruction.street_name
                }))
            };
            return mapRoutingData;
        });
    };
    const generateURL = (endpoint) => {
        return `${SLRestEndpoint}/${Routing.RoutingServiceName}/${endpoint}`;
    };
})(Routing || (Routing = {}));
