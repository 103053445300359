import axios from "axios";
import { SLRestEndpoint } from "../types";
export var Calendar;
(function (Calendar) {
    Calendar.CalendarServiceName = "calendar";
    Calendar.getCalendarFilter = (calendarName, filterName) => {
        const req = {
            calendar_name: calendarName,
            filter_name: filterName,
        };
        return new Promise((resolve, reject) => {
            axios({
                method: "POST",
                url: `${SLRestEndpoint}/${Calendar.CalendarServiceName}/get_filter`,
                data: req,
            }).then(rsp => {
                const data = rsp.data;
                resolve({
                    filter: data.filter,
                });
            });
        });
    };
    Calendar.setCalendarFilter = (filter, filterName, calendarName) => {
        return new Promise((resolve, reject) => {
            const req = {
                filter: filter,
                calendar_name: calendarName,
                filter_name: filterName,
            };
            axios({
                method: "POST",
                url: `${SLRestEndpoint}/${Calendar.CalendarServiceName}/set_filter`,
                data: req,
            }).then(rsp => {
                if (rsp.status === 200) {
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            });
        });
    };
})(Calendar || (Calendar = {}));
export default Calendar;
