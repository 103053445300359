import styled from "styled-components";
import React from "react";
import { ChildValidatorMenuBar } from "./ChildValidatorMenuBar";
import ChildValidatorCheckboxRow from "./ChildValidatorCheckboxRow";
import moment from "moment";
import { ChildValidatorHistoryTable } from "./ChildValidatorTable";
export class ChildOrder {
}
class ParentOrder {
}
function getParentOrderFromJson(json) {
    const order = new ParentOrder();
    order.assigned_fsr_login = getLoginFromStr(json["ASSIGNED_FSR_ID"]);
    order.assigned_fsr_login_description = json["ASSIGNED_FSR_ID"];
    order.order_number = json["ORDER_NUMBER"];
    order.order_id = parseInt(json["ORDER_ID"]);
    order.state_id = parseInt(json["STATE_ID"]);
    order.state_name = window.getStyleNameFromState(order.state_id, "Order ").split(" ")[1];
    return order;
}
function getChildOrderFromJson(json) {
    const order = new ChildOrder();
    order.assigned_fsr_login = getLoginFromStr(json["assigned_fsr"]);
    order.assigned_fsr_login_description = json["assigned_fsr"];
    order.state_id = parseInt(json["state_id"]);
    order.state_name = window.getStyleNameFromState(order.state_id, "Order ").split(" ")[1];
    order.return_requested = json["return_requested"];
    const dateValue = json["validated_time"];
    if (dateValue) {
        order.validated_time = moment(new Date(json["validated_time"])).format('YYYY-MM-DD HH:mm:ss');
    }
    order.assigned_fsr_id = json["fsr_id"];
    return order;
}
function getLoginFromStr(mixedLogin) {
    return mixedLogin.substring(mixedLogin.indexOf('(') + 1, mixedLogin.indexOf(')'));
}
function showToastBox(message, rcode, detailedMsg = undefined) {
    window.showStatusMsg(message, rcode, detailedMsg);
    if (rcode !== 2) {
        setTimeout(() => window.clearStatusMsg(), 3000);
    }
}
class ChildValidatorScreenProps {
}
class ChildValidatorScreenState {
}
const StyledMessageResenderScreen = styled.div `
  height: calc(100% - 20px);
  width: 100%;
  background-color: white;
  overflow: auto;
`;
class ChildValidatorScreen extends React.Component {
    constructor(props) {
        super(props);
        this.handleChildOrderSelected = (childOrderClicked) => {
            $("#childOrderDetails").html("");
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedChild: childOrderClicked, orderHistoryRows: null })));
        };
        this.getLogFromFsrId = async () => {
            const req = `<REQUEST FUNC="ORDERLOG_SEL" TIME="${moment(Date()).format(userSettings.default_date_format + " HH:mm:ss")}">
        <ORDER>
          <ID>${this.state.parentOrder.order_id}</ID>
          <FSR_ID>${this.state.selectedChild.assigned_fsr_id}</FSR_ID>
          <MODIFY_DATA>Y</MODIFY_DATA>
        </ORDER>
      </REQUEST>`;
            return await fetch("/sl3/orderLogView", {
                "headers": { "content-type": "application/x-www-form-urlencoded" },
                "body": "request=" + req,
                "method": "POST",
            })
                .then(rsp => rsp.text())
                .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
                .then(rspBody => {
                //nodes never empty, since only multi-assigned/completed orders run this code
                const nodes = rspBody.getElementsByTagName("ORDER_LOGS")[0].childNodes;
                //nodes sorted by most recent order log entry, so return first one (most recent)
                return nodes[0];
            });
        };
        this.handleDetailsClick = () => {
            if (!this.state.selectedChild) {
                showToastBox("No child orders selected", 1);
                return;
            }
            //fetch latest log, get matching assigned_fsr_login_description
            showToastBox("Loading details...", 2);
            this.getLogFromFsrId()
                .then(log => {
                //default to 0 when log w. fsr order isn't found
                const logId = log ? parseInt(log.getElementsByTagName("ID")[0].innerHTML) : 0;
                const fsrOrderId = log ? parseInt(log.getElementsByTagName("FSR_ORDER_ID")[0].innerHTML) : "";
                fetch(`/sl3/api/ordergroups/${this.state.parentOrder.order_id}/detail/${logId}`)
                    .then(rsp => rsp.json()).then(rspJson => {
                    window.clearStatusMsg();
                    $("#childOrderDetails")
                        .html(window.getOrderDetailsBody(this.state.parentOrder.order_id, rspJson.data, "detail", true, fsrOrderId));
                    window.applyImageView();
                });
            });
        };
        this.handleModifyClick = () => {
            if (!this.state.selectedChild) {
                showToastBox("No child orders selected", 1);
                return;
            }
            //todo, add ui to modify fsr orders
            fetch(`/sl3/api/ordergroups/${this.state.parentOrder.order_id}/modify/-1`, {
                "method": "GET",
                "headers": { "content-type": "application/x-www-form-urlencoded" },
            }).then(rsp => rsp.json()).then(rspJson => {
                $("#childOrderDetails").html(window.getOrderDetailsBody(this.state.parentOrder.order_id, rspJson.data, "modify", true));
            });
            if (true) {
                return;
            }
            const req = encodeURIComponent(`<REQUEST FUNC="ORDER_MODIFY" TIME="${moment(Date()).format(userSettings.default_date_format + " HH:mm:ss")}"><ORDERS><ORDER><ORDER_NUMBER>${this.state.parentOrder.order_number}</ORDER_NUMBER><ORDER_BASE_ID>${this.state.parentOrder.order_id}</ORDER_BASE_ID></ORDER></ORDERS></REQUEST>`);
            fetch("/sl3/order", {
                "headers": { "content-type": "application/x-www-form-urlencoded" },
                "method": "POST",
                "body": "request=" + req
            })
                .then(rsp => rsp.text())
                .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
                .then(rspBody => {
                if (rspBody.getElementsByTagName("RETURN_CODE")[0].textContent == "0") {
                    showToastBox("Modify Success", 0);
                }
                else {
                    showToastBox("Modify Failed", 1);
                }
            });
        };
        this.handleValidateClick = () => {
            if (!this.state.selectedChild) {
                showToastBox("No child orders selected", 1);
                return;
            }
            const req = JSON.stringify({
                fsr_id: this.state.selectedChild.assigned_fsr_id,
                order_id: this.state.parentOrder.order_id
            });
            showToastBox("Validating...", 2);
            fetch("/sl3/rest/fsrorder/validate", {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: req,
            })
                .then(res => res.json())
                .then(rspJson => {
                if (rspJson === 0) {
                    showToastBox("Validate Success", 0);
                }
                else {
                    showToastBox("Validate Failed", 1, rspJson.error.reason);
                }
            });
        };
        this.handleHistoryClick = () => {
            if (!this.state.selectedChild) {
                showToastBox("No child orders selected", 1);
                return;
            }
            showToastBox("Loading logs...", 2);
            const req = `<REQUEST FUNC="ORDERLOG_SEL" TIME="${moment(Date()).format(userSettings.default_date_format + " HH:mm:ss")}">
      <ORDER>
        <ID>${this.state.parentOrder.order_id}</ID>
        <FSR_ID>${this.state.selectedChild.assigned_fsr_id}</FSR_ID>
      </ORDER>
    </REQUEST>`;
            fetch("/sl3/orderLogView", {
                "headers": { "content-type": "application/x-www-form-urlencoded", },
                "body": "request=" + req,
                "method": "POST",
            })
                .then(rsp => rsp.text())
                .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
                .then(rspBody => {
                window.clearStatusMsg();
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { orderHistoryRows: rspBody.getElementsByTagName("ORDER_LOGS")[0].childNodes })));
            });
        };
        this.getOrderChildren = () => {
            let filterStr = encodeURIComponent(JSON.stringify({ "$and": [{ "$or": [{ "ORDER_NUMBER": { "$eq": this.props.orderNumber } }] }] }));
            fetch("/sl3/api/orders", {
                method: "POST",
                headers: { "content-type": "application/x-www-form-urlencoded" },
                body: "columns=ASSIGNED_FSR_ID" + "&filter=" + filterStr,
            })
                .then(res => res.json())
                .then(rspJson => {
                rspJson.data.forEach((parentJson) => {
                    const _parentOrder = getParentOrderFromJson(parentJson);
                    const _childOrders = [];
                    parentJson.FSR_ORDERS.forEach((childJson) => {
                        _childOrders.push(getChildOrderFromJson(childJson));
                    });
                    this.setState(prevState => (Object.assign(Object.assign({}, prevState), { childOrders: _childOrders, parentOrder: _parentOrder })));
                });
            })
                .catch(console.error);
        };
        this.state = {
            selectedChild: null,
            childOrders: [],
            parentOrder: null,
            orderHistoryRows: null
        };
    }
    render() {
        return (React.createElement(StyledMessageResenderScreen, { className: "child-validator-screen-container" },
            React.createElement("div", null,
                "Parent Order:",
                !this.state.parentOrder ? this.props.orderNumber : (React.createElement("span", null,
                    "\u00A0\u00A0",
                    this.state.parentOrder.order_number,
                    "\u00A0\u00A0-\u00A0\u00A0",
                    this.state.parentOrder.state_name,
                    "\u00A0\u00A0-\u00A0\u00A0",
                    this.state.parentOrder.assigned_fsr_login))),
            React.createElement(ChildValidatorMenuBar, { handleDetailsClick: this.handleDetailsClick, handleModifyClick: this.handleModifyClick, handleValidateClick: this.handleValidateClick, handleHistoryClick: this.handleHistoryClick }),
            this.state.childOrders.map((childOrder) => {
                return (React.createElement(ChildValidatorCheckboxRow, { order: childOrder, handleChildSelected: this.handleChildOrderSelected }));
            }),
            React.createElement("div", { id: "childOrderDetails" }),
            this.state.orderHistoryRows ? (React.createElement(ChildValidatorHistoryTable, { orderHistoryRows: this.state.orderHistoryRows })) : ""));
    }
    componentDidMount() {
        this.getOrderChildren();
    }
}
export default ChildValidatorScreen;
