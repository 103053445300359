/* Global variables - Start */
// Page specific
import {
  creators,
  dispatchSettingsChanged,
  setSelectedOrders
} from "../../state/containers/DispatchPageContainer/actions";

import axios from "axios";
import wrappedInit from "../modules/routing";

export const listenToField = (field, fieldName, updateActionCallbacks, update) => {
  Object.defineProperty(window, fieldName, {
    configurable: true,
    get: () => field,
    set: value => {
      if (window.store && update(value)) {
        updateActionCallbacks.forEach(updateActionCallback => {
          window.store.dispatch(updateActionCallback(value));
        });
      }
      field = value;
    }
  });
};

export const getExtractedJFilter = (filterText) => {
  const extractedJFilter = /filter=(.*)&filter_date/.exec(filterText);
  if (extractedJFilter) {
    return extractedJFilter ? extractedJFilter[1] : null;
  } else {
    const filterArray = filterText.split("filter@").map(s => s.replace("|", "").split("!filter_date")[0]);
    filterArray.shift();
    let filterString = '{"$and":[{"$or":[';
    if (filterArray.length > 1) {
      for (let i = 0; i < filterArray.length; i++) {
        if (i === filterArray.length - 1) {
          filterString = filterString + filterArray[i] + "]}]}";
        } else {
          filterString = filterString + filterArray[i] + ",";
        }
      }
      return filterString;
    } else {
      return null;
    }
  }
};

export const selectedStringArrayToNumberArray = (stringArray) => {
  return stringArray.map(s => parseInt(s.split(",")[0]))
};

export const selectedStringArrayToFullOrder = (stringArray) => {
  const selectedIdsSet = new Set(stringArray.map(x => x.split(",")[0]).map(x => parseInt(x)));
  return Array.from($("#orderTableId > tbody > tr"))
    .map($)
    .filter(jqueryRow => selectedIdsSet.has(jqueryRow.data("obj_id")))
    .map(row => JSON.parse(decodeURI(row.data("payload"))))
    .map(window.sl.convertLegacyOrder)
};

export const addFieldListeners = () => {
  window.oFilterSql = "";
  window.orderPools = [];
  window.orderSelectedOnDrag = [];
  window.fsrSelectedOnDrag = [];
  window.aFSRs = [];
  window._fsrEventSource = false;
  window.pollerAttached = false;
  window.dragOn = false;
  window.correctTarget = false;
  window.dragType = "";
  window.dropType = "";
  window.isOrderFirstBindEvent = true;
  window.isFsrFirstBindEvent = true;
  window.isLegacyDragDrop = false;

  window.dispFilters = null;
  window.isThisDrag = false; //for Drag and drop

  window._isShowChildren = false;
  window._isShowFSROrders = false;
  window._isShowOrderTasks = false;
  window._isProxyMsg = false;
  window._initManageDataLoad = "";
  window._helpMenuForDispatch = "mainScreen";
  window.isHoldRecommend = false;
  window.isDragRecommend = false;
  let _isRecommend = false;
  listenToField(_isRecommend, "_isRecommend", [(value) => {
    return dispatchSettingsChanged({isRecommending: value});
  }], () => {return true});

  window.pressTimeout = null;
  window.clearPressTimeout = clearPressTimeout;
  function clearPressTimeout(doRefresh) {
    if (window.pressTimeout) {
      clearTimeout(window.pressTimeout);
    }
    window.dragOn = false;
    if (window._isRecommend && window.fsrVehMode === "fsr" && doRefresh) {
      $("#" + "f" + "table_refresh").trigger("click"); //refresh table
      window.isHoldRecommend = false;
      window.isDragRecommend = false;
    }
  }

  window.addEventListener("mouseup", (e) => {
    if (e.shiftKey) {
      window.clearPressTimeout(false);
    } else {
      if (window.isHoldRecommend) {
        window.clearPressTimeout(true);
      } else {
        window.clearPressTimeout(false);
      }
    }
  });

  window.addEventListener("dragend", (e) => {
    if (e.shiftKey) {
      window.clearPressTimeout(false);
    } else {
      if (window.isHoldRecommend) {
        window.clearPressTimeout(true);
      } else {
        window.clearPressTimeout(false);
      }
    }
  });

  let oFilterTxt = null;
  listenToField(oFilterTxt, "oFilterTxt", [
    (value) => {
      return {
        type: "dispatch.page.SET_ORDER_FILTER_STRING",
        orderFilterString: getExtractedJFilter(value)
      }
    },
    (value) => {
      return {
        type: "dispatch.page.UPDATE_MAP_ICONS"
      }
    }
  ], (value) => {
    return getExtractedJFilter(value) !== window.store.getState().dispatch.orderFilterString;
  });
  let fFilterTxt = null;
  listenToField(fFilterTxt, "fFilterTxt", [
    (value) => {
      return {
        type: "dispatch.page.SET_FSR_FILTER_STRING",
        fsrFilterString: getExtractedJFilter(value)
      }
    },
    (value) => {
      return {
        type: "dispatch.page.UPDATE_MAP_ICONS"
      }
    }
  ], (value) => {
    return getExtractedJFilter(value) !== window.store.getState().dispatch.fsrFilterString;
  });
  let vFilterTxt = null;
  listenToField(vFilterTxt, "vFilterTxt", [
    (value) => {
      return {
        type: "dispatch.page.SET_VEHICLE_FILTER_STRING",
        vehicleFilterString: getExtractedJFilter(value)
      }
    },
    (value) => {
      return {
        type: "dispatch.page.UPDATE_MAP_ICONS"
      }
    }
  ], (value) => {
    return getExtractedJFilter(value) !== window.store.getState().dispatch.vehicleFilterString;
  });

  let orderSelected = [];
  Object.defineProperty(window, "orderSelected", {
    configurable: true,
    get: () => orderSelected,
    set: value => {
      const update = (value) => {
        return selectedStringArrayToNumberArray(value).toString() !== window.store.getState().dispatch.selectedOrderIds.toString();
      };
      if (window.store && update(value)) {
        const newSelectedOrderIds = selectedStringArrayToNumberArray(value);
        const newSelectedOrders = selectedStringArrayToFullOrder(value);
        const oldCachedOrdersArray = window.store.getState().dispatch.cachedOrders;
        const oldCachedOrderIds = oldCachedOrdersArray.map((o) => o.id);
        const ordersToAdd = newSelectedOrders.filter((o) => !oldCachedOrderIds.includes(o.id));
        const newCachedOrderArray = [...oldCachedOrdersArray.map((o) => ({...o})), ...ordersToAdd];
        const newUpdatedCachedOrderArray = newCachedOrderArray.map((o, i) => {
          if (oldCachedOrderIds.includes(o.id)) {
            const newOrderObject = newSelectedOrders.find((newOrder) => newOrder.id === o.id);
            if (newOrderObject) {
              return {
                ...newOrderObject,
              }
            } else {
              return {
                ...o,
              }
            }
          } else {
            return {
              ...o,
            }
          }
        });
        const newUpdatedCachedOrderIdArray = newUpdatedCachedOrderArray.map((o) => o.id);
        const missingCachedOrderIds = newSelectedOrderIds.filter(id => !newUpdatedCachedOrderIdArray.includes(id));
        if (missingCachedOrderIds.length > 0) {
          window.getOrdersFromIdArray(missingCachedOrderIds).then((rsp) => {
            window.store.dispatch({
              type: "dispatch.page.SET_CACHED_ORDERS",
              cachedOrders: newUpdatedCachedOrderArray,
            });

            const newSelectedOrdersWithCache = [...newUpdatedCachedOrderArray, ...rsp.response].filter((o) => newSelectedOrderIds.includes(o.id));
            window.store.dispatch({
              type: "dispatch.page.SET_SELECTED_ORDERS",
              selectedOrders: newSelectedOrdersWithCache,
            });
            window.store.dispatch({
              type: "dispatch.page.SET_SELECTED_ORDER_IDS",
              selectedOrderIds: newSelectedOrderIds,
            });
            window.store.dispatch({
              type: "dispatch.page.UPDATE_MAP_SELECT",
            });
          });
        } else {
          window.store.dispatch({
            type: "dispatch.page.SET_CACHED_ORDERS",
            cachedOrders: newUpdatedCachedOrderArray,
          });

          const newSelectedOrdersWithCache = newUpdatedCachedOrderArray.filter((o) => newSelectedOrderIds.includes(o.id));
          window.store.dispatch({
            type: "dispatch.page.SET_SELECTED_ORDERS",
            selectedOrders: newSelectedOrdersWithCache,
          });
          window.store.dispatch({
            type: "dispatch.page.SET_SELECTED_ORDER_IDS",
            selectedOrderIds: newSelectedOrderIds,
          });
          window.store.dispatch({
            type: "dispatch.page.UPDATE_MAP_SELECT",
          });
        }
      }
      orderSelected = value;
    }
  });

  let fsrSelected = [];
  listenToField(fsrSelected, "fsrSelected", [
    (value) => {
      return {
        type: "dispatch.page.SET_SELECTED_FSR_IDS",
        selectedFsrIds: selectedStringArrayToNumberArray(value)
      }
    },
    (value) => {
      return {
        type: "dispatch.page.UPDATE_MAP_SELECT"
      }
    }
  ], (value) => {
    return selectedStringArrayToNumberArray(value).toString() !== window.store.getState().dispatch.selectedFsrIds.toString();
  });

  let vehicleSelected = [];
  listenToField(vehicleSelected, "vehicleSelected", [
    (value) => {
      return {
        type: "dispatch.page.SET_SELECTED_VEHICLE_IDS",
        selectedVehicleIds: selectedStringArrayToNumberArray(value)
      }
    },
    (value) => {
      return {
        type: "dispatch.page.UPDATE_MAP_SELECT"
      }
    }
  ], (value) => {
    return selectedStringArrayToNumberArray(value).toString() !== window.store.getState().dispatch.selectedVehicleIds.toString();
  });

  let fsrVehMode = "fsr";
  listenToField(fsrVehMode, "fsrVehMode", [
    (value) => {
      return {
        type: "dispatch.page.SET_FSR_VEH_MODE",
        fsrVehMode: value
      }
    },
    (value) => {
      return {
        type: "dispatch.page.UPDATE_MAP_ICONS"
      }
    }
  ], (value) => {
    return value !== window.store.getState().dispatch.fsrVehMode;
  });

};

/* Global variables - End */

export const init = () => {
  addFieldListeners();
  if (!window.EventSource){
    document.write(`<script src="/sl3/scripts/eventsource.min.js?v=1.15"></script>`);
  }
  sl.checkDocReferrer();
  wrappedInit();

  if (window.pollerAttached === false) {
    window.setInterval(() => {
      dispatchStateStartPoller();
    }, 1000);
    window.pollerAttached = true;
  }

  const pollerCountMax = 5;
  let pollerCount = 0;
  const dispatchStateStartPoller = () => {
    if (pollerCount < pollerCountMax) {
      pollerCount++;
    }
    const doUpdate = pollerCount < pollerCountMax;

    if (doUpdate && window.oFilterTxt) {
      const orderFilterString = window.oFilterTxt;
      window.store.dispatch({
        type: "dispatch.page.SET_ORDER_FILTER_STRING",
        orderFilterString: getExtractedJFilter(orderFilterString)
      });
    }

    if (doUpdate && window.fFilterTxt) {
      window.store.dispatch({
        type: "dispatch.page.SET_FSR_FILTER_STRING",
        fsrFilterString: getExtractedJFilter(window.fFilterTxt)
      });
    }

    if (doUpdate && window.vFilterTxt) {
      window.store.dispatch({
        type: "dispatch.page.SET_VEHICLE_FILTER_STRING",
        vehicleFilterString: getExtractedJFilter(window.vFilterTxt)
      });
    }

    if (doUpdate && window.orderSelected.length > 0) {
      window.store.dispatch({
        type: "dispatch.page.SET_SELECTED_ORDER_IDS",
        selectedOrderIds: selectedStringArrayToNumberArray(window.orderSelected)
      });
    }

    if (doUpdate && window.fsrSelected.length > 0) {
      window.store.dispatch({
        type: "dispatch.page.SET_SELECTED_FSR_IDS",
        selectedFsrIds: selectedStringArrayToNumberArray(window.fsrSelected)
      });
    }

    if (doUpdate && window.vehicleSelected.length > 0) {
      window.store.dispatch({
        type: "dispatch.page.SET_SELECTED_VEHICLE_IDS",
        selectedVehicleIds: selectedStringArrayToNumberArray(window.vehicleSelected)
      });
    }
  };

  if (!!window.buildBanner) {
    window.buildBanner();
  }
  const settingsURL = "/sl3/rest/settings";
  axios({
    method: "GET",
    url: settingsURL,
  }).then(rsp => {
    const settings = rsp.data;
    window.settings = settings;
    const mapSettingsURL = "/sl3/rest/map/settings";
    axios({
      method: "GET",
      url: mapSettingsURL,
    }).then(rsp => {
      const mapSettings = rsp.data;
      window.mapSettings = mapSettings;
      mapInit();
      if (mapSettings.type === "ESRI") {
        window.sl.mountESRIMap();
      }
      return getFirstPromise();
    }).catch(error => {
      console.error(`Unable to get map settings from ${mapSettingsURL}.\n${error}`);
      mapInit();
      return getFirstPromise();
    });
  });
};

const getFirstPromise = () => {
  window.fsrVehMode = userSettings["user_setting_object"]["fsr_veh_mode" + winNum] || "fsr";
  window.dispFilters = userSettings["user_setting_object"]["disp_filters" + winNum] || [
    //stored in db in this format: com.emdi.sl3.server.dispatch.ejb.filters.DispatchAreaFilter
    "AreaFilter",
    "AttributesFilter",
    "BlackOutFilter",
    "EmergencyFilter",
    "ShiftFilter",
    "ShiftWindowFilter",
    "TouchesShiftFilter",
    "AppointmentFilter",
    "AvailableTimeFilter",
    "AvailableUnavailableFilter",
    "NoopFilter"
  ];

  //splitter
  let filterClose = userSettings["user_setting_object"]["filter_close" + winNum], //Close filter by default
    ftableClose = userSettings["user_setting_object"]["ftable_close" + winNum], //Close FSR table by default
    otableClose = userSettings["user_setting_object"]["otable_close" + winNum], //Close Order table by default
    mapClose = userSettings["user_setting_object"]["maps_close" + winNum], //Close map by default - both FSR and map can not be true
    defSplit = userSettings["user_setting_object"]["def_split" + winNum],
    splotPosTbl = userSettings["user_setting_object"]["split_pos0" + winNum] || 0.5,
    splotPosMap = userSettings["user_setting_object"]["split_pos1" + winNum] || 0.5;
  if (filterClose == undefined) filterClose = true;
  if (ftableClose == undefined) ftableClose = false;
  if (otableClose == undefined) otableClose = false;
  if (mapClose == undefined) mapClose = false;
  if (defSplit == undefined) defSplit = true;

  window.tableSplitters = function (switchView) {
    if (switchView) {
      otableClose = !$("#otable_main").is(":visible");
      ftableClose = !$("#ftable_main").is(":visible");
      mapClose = !$("#maps_main").is(":visible");

      $("#otable_main, #maps_main ").show();
      $("#otable_short_v, #otable_short_h, #ftable_short_v, #ftable_short_h,#maps_short_v, #maps_short_h").hide();
    }

    $("#tables_container").splitter({
      splitHorizontal: !defSplit,
      outline: false,
      minAsize: 40,
      minBsize: 50,
      A: $("#otable_div"),
      B: $("#fsr_map"),
      filterClose: filterClose,
      position: "50%",
      type: 0, //Order Table
      splitPerc: splotPosTbl
    });

    // Overiding the width of the tables div.
    // This is set by a style element dynamically somewhere (probably needs refactoring to make code cleaner).
    //$('div#tables').css('width', 'auto');
    $("#fsr_map_container").splitter({
      splitHorizontal: defSplit,
      outline: false,
      A: $("#ftable_div"),
      B: $("#maps"),
      position: "50%",
      type: 1, //FSR and Map
      splitPerc: splotPosMap
    });
    loadUserSettingsFromStorage();
    userSettings["user_setting_object"]["def_split" + winNum] = defSplit;
    localStorage.userSettings = JSON.stringify([userSettings]);
    if (switchView) {
      if (otableClose) $("#otable_title .otable_open_close").trigger("click");
      if (ftableClose) $("#ftable_title .ftable_open_close").trigger("click");
      if (mapClose) $("#maps_title .maps_open_close").trigger("click");
    }
    defSplit = !defSplit;
  };

  tableSplitters(false);

  if (mapClose) closeMapPanel();

  function closeMapPanel() {
    $("#maps_title .maps_open_close").trigger("click");
  }

  $("#filter_container").splitter({
    splitHorizontal: true,
    outline: true,
    A: $("#ofilter_div"),
    B: $("#ffilter_div"),
    type: 2, //Filters
    splitPerc: 0.6
  });

  let firstPromise = $.ajax({
    type: "GET",
    url: "/sl3/api/filters?type=order&module=filter",
    dataType: "json",
    cache: false
  }).then((rsp) => {
    fetch("/sl3/rest/globalfilters")
        .then(globalFilterRsp => {
          return globalFilterRsp.json();
        })
        .then(globalFilterRspData => {
          sl.orderFilterList = rsp.fields;
          sl.sqlDialect = ["ORACLE","SQLSERVER","POSTGRES"].indexOf(rsp.sql_dialect);
          sl.hist_order_filters = rsp.hist_order_filters.length ? rsp.hist_order_filters : [{column: "ORDER_NUMBER", match: "EXACT", type: "SEARCH", order_types:[]}];
          sl.hist_order_filters.forEach((filter) => {filter.column = filter.column.toUpperCase()});
          $("#ofilter_div").buildFilter({
            filterDiv: $("#ofilter_main"),
            filter_close: filterClose,
            filterType: "order",
            filterList: rsp.fields,
            sqlDialect: sl.sqlDialect,
            savedFilters: rsp.saved_filters,
            globalSearchFields: rsp.global_search_fields[0].fields,
            savedGlobalFilters : globalFilterRspData.data
          })
          $("#otable_div").buildDispatchTable({
            refreshBtn: $("#otable_refresh"),
            otable_close: otableClose,
            setSize: 80,
            tblName: "order"
          });
          let switchFsrVehicleMode1 = switchFsrVehicleMode(window.fsrVehMode);
          return switchFsrVehicleMode1;
        });
  });

  shortcut.add("F1", function() {
    $("body").attr("ondragstart", "return true");
    window.sl.buildHelpMenuOptions();
  });

  pageLoadStatus(false);
  return firstPromise;
};

export default init;
