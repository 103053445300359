import axios from "axios";
import { SLRestEndpoint } from "../types";
import { MapUtils } from "../../../../display/web/uils/MapUtils";
export var Map;
(function (Map) {
    Map.MapServiceName = "map";
    let MapEndpointName;
    (function (MapEndpointName) {
        MapEndpointName["SETTINGS"] = "settings";
        MapEndpointName["ICONS"] = "icons";
    })(MapEndpointName = Map.MapEndpointName || (Map.MapEndpointName = {}));
    Map.getMapSettings = () => {
        return axios({
            method: "GET",
            url: generateURL(MapEndpointName.SETTINGS)
        }).then(rsp => {
            const settings = rsp.data;
            return settings;
        }).then(settings => {
            return {
                type: settings.type,
                urls: settings.urls,
                featureLayerUrls: settings.feature_layer_urls,
                featureLayerNames: settings.feature_layer_names,
                featureLayerPopupsEnabled: settings.feature_layer_popups_enabled,
                webViewUrls: settings.web_view_urls,
                iconSize: settings.icon_size,
                maxChildrenCount: settings.max_children_count,
                urlPrefix: settings.url_prefix,
                proxyUrl: settings.proxy_url,
                tokenUrl: settings.token_url,
                tokenMethod: settings.token_method,
                tokenRequest: settings.token_request
            };
        });
    };
    Map.fetchEsriToken = (url, method, request) => {
        const reqJsonObj = JSON.parse(request);
        const reqStr = new URLSearchParams(reqJsonObj).toString();
        const strictMethod = method.toUpperCase() === "POST" ? "POST" : "GET";
        const tokenUrl = url + "?" + reqStr;
        return axios({
            url: tokenUrl,
            method: strictMethod,
        }).then(rsp => {
            const tokenObj = rsp.data;
            let tokenStr;
            let expiryTimestamp = new Date().getTime();
            if (tokenObj.token) {
                tokenStr = tokenObj.token;
                expiryTimestamp = tokenObj.expires;
            }
            if (tokenObj.access_token) {
                tokenStr = tokenObj.access_token;
                expiryTimestamp += (tokenObj.expires_in * 1000);
            }
            sessionStorage.setItem(MapUtils.ESRI_TOKEN, JSON.stringify({ "token": tokenStr, "expire": expiryTimestamp }));
            return tokenStr;
        });
    };
    Map.getLayerGroups = (urls, featureLayers, token, hiddenLayerNames) => {
        return new Promise((resolve, reject) => {
            const promises = urls.map((url, index) => axios({
                method: "GET",
                url: `${url}/layers?f=json${token ? "&token=" + token : ""}`,
            }));
            Promise.all(promises).then((data) => {
                const esriLayerGroups = [];
                const layersData = data.map(rsp => {
                    var _a, _b, _c;
                    if (!!rsp.data.layers) {
                        return rsp.data.layers;
                    }
                    else {
                        console.error(`Unable to load layer: [${(_a = rsp === null || rsp === void 0 ? void 0 : rsp.config) === null || _a === void 0 ? void 0 : _a.url}]. Error: ${(_c = (_b = rsp === null || rsp === void 0 ? void 0 : rsp.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.message}.`);
                        return null;
                    }
                });
                layersData.forEach((layers, index) => {
                    if (!!layers) {
                        //workaround to get lines under other icons for GLWA: last layer id is the one that esri puts at the bottom (on GLWA this is id 0-> road lines)
                        const sortedLayers = layers.sort((layer1, layer2) => layer1.id < layer2.id ? 1 : -1);
                        const esriLayers = sortedLayers.map(layer => {
                            const isVisible = !featureLayers.length || featureLayers.includes(layer.name);
                            if (!isVisible) {
                                hiddenLayerNames.push(layer.name);
                            }
                            return {
                                url: urls[index],
                                id: layer.id,
                                name: layer.name,
                                type: layer.type,
                                description: layer.description,
                                defaultVisibility: isVisible,
                                subLayers: layer.subLayers || [],
                            };
                        });
                        esriLayerGroups.push({
                            url: urls[index],
                            layers: esriLayers,
                        });
                    }
                });
                resolve(esriLayerGroups);
            }).catch(errorMsg => {
                console.error(`Layer loading failed, failed calling endpoint: ${errorMsg}.`);
            });
        });
    };
    Map.getMapIconsData = (req) => {
        return axios({
            method: "POST",
            url: generateURL(MapEndpointName.ICONS),
            data: req
        }).then(rsp => {
            var _a;
            const serverDataArray = ((_a = rsp === null || rsp === void 0 ? void 0 : rsp.data) === null || _a === void 0 ? void 0 : _a.map_icons) || [];
            const dataArray = serverDataArray.map(serverData => {
                return {
                    lat: serverData.lat,
                    lon: serverData.lon,
                    childrenCount: serverData.children_count,
                    emergency: serverData.emergency,
                    sameState: serverData.same_state,
                    sameStatus: serverData.same_status,
                    type: serverData.type,
                    state: serverData.state,
                    status: serverData.status,
                    children: serverData.children.map(childData => {
                        return {
                            name: childData.name,
                            type: childData.type,
                            id: childData.id,
                            legacyIdentifier: childData.legacy_identifier,
                            emergency: childData.emergency,
                            state: childData.state,
                            status: childData.status,
                            customIcon: childData.custom_icon
                        };
                    }),
                    orderIds: serverData.order_ids,
                    fsrIds: serverData.fsr_ids,
                    crewIds: serverData.crew_ids,
                    vehicleIds: serverData.vehicle_ids,
                    projectIds: serverData.project_ids,
                    customIcon: serverData.custom_icon
                };
            });
            return dataArray;
        });
    };
    Map.getMapHoverData = (orderId) => {
        return axios({
            method: "POST",
            url: "/sl3/api/orders",
            data: `tooltip=true&id=${orderId}&field=map`
        }).then((rsp) => {
            return rsp.data;
        }).catch((e) => {
            return null;
        });
    };
    const generateURL = (endpoint) => {
        return `${SLRestEndpoint}/${Map.MapServiceName}/${endpoint}`;
    };
})(Map || (Map = {}));
export default Map;
