import { call, put, select, takeEvery } from "redux-saga/effects";
import { ManagePageContainerActionTypes } from "./types";
import Calendar from "../../api/REST/Calendar/Calendar";
import Traccar from "../../api/REST/Traccar/Traccar";
import { Utils } from "../../../helper/General/Utils";
export const managePageContainerSagas = {
    userFilterWindowInitSaga: takeEvery(ManagePageContainerActionTypes.USER_FILTER_WINDOW_INIT, userFilterWindowInitSaga),
    saveUserFilterSaga: takeEvery(ManagePageContainerActionTypes.SAVE_USER_FILTER, saveUserFilterSaga),
    calendarFilterWindowInitSaga: takeEvery(ManagePageContainerActionTypes.CALENDAR_FILTER_WINDOW_INIT, calendarFilterWindowInitSaga),
    openBindVehicleWindowSaga: takeEvery(ManagePageContainerActionTypes.OPEN_BIND_VEHICLE_WINDOW, openBindVehicleWindowSaga),
    bindVehicleSaga: takeEvery(ManagePageContainerActionTypes.BIND_VEHICLE, bindVehicleSaga),
    openUnbindVehicleWindowSaga: takeEvery(ManagePageContainerActionTypes.OPEN_UNBIND_VEHICLE_WINDOW, openUnbindVehicleWindowSaga),
    unbindVehicleSaga: takeEvery(ManagePageContainerActionTypes.UNBIND_VEHICLE, unbindVehicleSaga),
    saveCalendarFilterSaga: takeEvery(ManagePageContainerActionTypes.SAVE_CALENDAR_FILTER, saveCalendarFilterSaga),
};
function* userFilterWindowInitSaga(action) {
    const dispatchState = yield select(getDispatchPageContainerStateSelector);
    const filterSettings = dispatchState.filterSettings;
    const filterType = action.filterType;
    const filterSetting = filterSettings[filterType];
    const filterFields = filterSetting.filterFields;
    const filterString = window.sl.thisObj[`${filterType}_filter`];
    const decodedFilterString = decodeURIComponent(filterString).replace(/\\"/g, '"');
    const filterObject = {
        name: action.filterName,
        filter: JSON.parse(decodedFilterString),
    };
    yield put({
        type: ManagePageContainerActionTypes.SET_USER_FILTER,
        userFilterFields: filterFields,
        userFilterType: filterType,
        userFilter: filterObject,
    });
}
function* saveUserFilterSaga(action) {
    const unEncodedNewFilterString = JSON.stringify(action.filter).replace(/"/g, '\\"');
    const newFilterString = encodeURIComponent(unEncodedNewFilterString);
    const filterType = action.filterType;
    const filterName = action.filterName;
    window.sl.thisObj.order_filter = filterType === "order" ? newFilterString : window.sl.thisObj.order_filter;
    window.sl.thisObj.order_filter_name = filterType === "order" ? filterName : window.sl.thisObj.order_filter_name;
    window.sl.thisObj.fsr_filter = filterType === "fsr" ? newFilterString : window.sl.thisObj.fsr_filter;
    window.sl.thisObj.fsr_filter_name = filterType === "fsr" ? filterName : window.sl.thisObj.fsr_filter_name;
    window.sl.thisObj.avl_filter = filterType === "avl" ? newFilterString : window.sl.thisObj.avl_filter;
    window.sl.thisObj.avl_filter_name = filterType === "avl" ? filterName : window.sl.thisObj.avl_filter_name;
}
function* calendarFilterWindowInitSaga(action) {
    const dispatchState = yield select(getDispatchPageContainerStateSelector);
    const filterSettings = dispatchState.filterSettings;
    const filterType = "order";
    const filterSetting = filterSettings[filterType];
    const filterFields = filterSetting.filterFields;
    const filterName = action.filterName;
    const calendarName = action.calendarName;
    const filterStringResponse = yield call(Calendar.getCalendarFilter, calendarName, filterName);
    const filterString = filterStringResponse.filter;
    let filter = {};
    try {
        filter = JSON.parse(filterString);
    }
    catch (e) {
        console.error("Unable to parse calendar filter string.");
    }
    yield put({
        type: ManagePageContainerActionTypes.SET_CALENDAR_FILTER,
        calendarFilterFilterName: filterName,
        calendarFilterCalendarName: calendarName,
        calendarFilterFields: filterFields,
        calendarFilter: {
            name: filterName,
            filter: filter,
        }
    });
    yield put({
        type: ManagePageContainerActionTypes.SET_CALENDAR_FILTER_OPEN,
        open: true,
    });
}
function* openBindVehicleWindowSaga(action) {
    const vehicles = yield call(Traccar.getVehicles);
    const boundVehicles = yield call(Traccar.getBoundVehicles);
    const devices = yield call(Traccar.getDevices);
    const boundVehicleUniqueIds = boundVehicles.map(boundVehicle => boundVehicle.uniqueId);
    const unboundDevices = devices.filter(device => !boundVehicleUniqueIds.includes(device.uniqueId));
    const boundVehicleIds = boundVehicles.map(boundVehicle => boundVehicle.id);
    const unboundVehicles = vehicles.filter(vehicle => !boundVehicleIds.includes(vehicle.id));
    yield put({
        type: ManagePageContainerActionTypes.SET_BIND_VEHICLE_WINDOW_STATE,
        devices: unboundDevices,
        vehicles: unboundVehicles,
    });
}
function* bindVehicleSaga(action) {
    const req = {
        vehicle: {
            id: action.vehicle.id,
            bu_id: action.vehicle.buId,
            name: action.vehicle.name,
            unique_id: action.vehicle.uniqueId,
        },
        device: action.device,
    };
    yield call(Traccar.bindVehicle, req);
}
function* openUnbindVehicleWindowSaga(action) {
    const boundVehicles = yield call(Traccar.getBoundVehicles);
    const buId = parseInt(Utils.getCookieByName("BUID"));
    const boundVehiclesWithBuId = boundVehicles.map(boundVehicle => ({
        id: boundVehicle.id,
        buId: buId,
        name: boundVehicle.name,
        uniqueId: boundVehicle.uniqueId,
    }));
    yield put({
        type: ManagePageContainerActionTypes.SET_UNBIND_VEHICLE_WINDOW_STATE,
        boundVehicles: boundVehiclesWithBuId,
    });
}
function* unbindVehicleSaga(action) {
    const req = {
        id: action.vehicle.id,
        bu_id: action.vehicle.buId,
        name: action.vehicle.name,
        unique_id: action.vehicle.uniqueId,
    };
    yield call(Traccar.unbindVehicle, req);
}
function* saveCalendarFilterSaga(action) {
    const manageState = yield select(getManagePageContainerStateSelector);
    const filterString = JSON.stringify(action.filter);
    const success = yield call(Calendar.setCalendarFilter, filterString, action.filterName, action.calendarName);
    if (success) {
        yield put({
            type: ManagePageContainerActionTypes.SET_CALENDAR_FILTER,
            calendarFilterFilterName: action.filterName,
            calendarFilterCalendarName: action.calendarName,
            calendarFilterFields: manageState.calendarFilterFields,
            calendarFilter: {
                name: action.filterName,
                filter: action.filter,
            }
        });
    }
}
function getDispatchPageContainerStateSelector(store) {
    return store.dispatch;
}
function getManagePageContainerStateSelector(store) {
    return store.manage;
}
export default managePageContainerSagas;
