import axios from "axios";
import { SLRestEndpoint } from "../types";
export var ConfigJson;
(function (ConfigJson) {
    ConfigJson.ConfigJsonServiceName = "config_json";
    let ConfigJsonEndpointName;
    (function (ConfigJsonEndpointName) {
        ConfigJsonEndpointName["TRACCAR"] = "traccar";
        ConfigJsonEndpointName["ACTIVE_DIRECTORY"] = "ad";
    })(ConfigJsonEndpointName = ConfigJson.ConfigJsonEndpointName || (ConfigJson.ConfigJsonEndpointName = {}));
    ConfigJson.getTraccarSettings = () => {
        return axios({
            method: "GET",
            url: generateURL(ConfigJsonEndpointName.TRACCAR),
        }).then(rsp => {
            const settings = rsp.data;
            return settings;
        }).then(settings => {
            return {
                host: settings.host,
                port: settings.port,
                adminEmail: settings.admin_email,
                adminPassword: settings.admin_password,
            };
        });
    };
    ConfigJson.getActiveDirectorySettings = () => {
        return axios({
            method: "GET",
            url: generateURL(ConfigJsonEndpointName.ACTIVE_DIRECTORY),
        }).then(rsp => {
            const settings = rsp.data;
            return settings;
        }).then(settings => {
            return {
                isEnabled: settings.enabled,
                mode: settings.mode,
                clientId: settings.client_id,
                authority: settings.authority,
                tenant: settings.tenant,
                doMicrosoftLogout: settings.do_microsoft_logout,
                slAuthEnabled: settings.sl_auth_enabled || !settings.enabled,
                slAdEmailEnabled: settings.sl_ad_email_enabled,
                plainAdLoginEnabled: settings.plain_ad_login_enabled
            };
        });
    };
    window["getActiveDirectorySettings"] = ConfigJson.getActiveDirectorySettings;
    ConfigJson.getBuildVersion = () => {
        return axios({
            method: "GET",
            url: "/sl3/version/constants.xml",
        }).then(constants => {
            let buildVersion = "7";
            if (constants.data) {
                buildVersion = constants.data.match(/(?<=BuildDesc>\s*).*?(?=\s*<\/BuildDesc)/gs);
            }
            return buildVersion;
        });
    };
    const generateURL = (endpoint) => {
        return `${SLRestEndpoint}/${ConfigJson.ConfigJsonServiceName}/${endpoint}`;
    };
})(ConfigJson || (ConfigJson = {}));
export default ConfigJson;
