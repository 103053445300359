import React from "react";
import styled from "styled-components";
import ErrorMessageDetailsContent from "./ErrorMessageDetailsContent/ErrorMessageDetailsContent";
import ErrorMessageDetailsMessages from "./ErrorMessageDetailsMessages/ErrorMessageDetailsMessages";
import ErrorMessageDetailsOrders from "./ErrorMessageDetailsOrders/ErrorMessageDetailsOrders";
import ErrorMessageDetailsUpdate from "./ErrorMessageDetailsUpdate/ErrorMessageDetailsUpdate";
const StyledErrorMessageDetailsScreen = styled.div `
  height: calc(100% - 20px);
  width: 100%;
  background-color: white;
`;
class ErrorMessageDetailsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.handleOrderRowClick = (event) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedOrderId: event.orderId, selectedMessageId: 0, orderErrorDetailsOriginal: [], orderErrorDetailsChanged: [] })), () => {
                this.updateOrderMessages(this.state.selectedOrderId);
            });
        };
        this.handleMessageRowClick = (event) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedMessageId: event.messageId })));
        };
        this.handleCheckboxChange = (event) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { orderErrorDetailsChanged: prevState.orderErrorDetailsChanged.map(message => {
                    if (message.messageId === event.id) {
                        return Object.assign(Object.assign({}, message), { skip: event.isChecked });
                    }
                    else {
                        return message;
                    }
                }) })));
        };
        this.handleDataChange = (data, messageId) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { orderErrorDetailsChanged: prevState.orderErrorDetailsChanged.map(detail => {
                    if (detail.messageId === messageId) {
                        return Object.assign(Object.assign({}, detail), { data: data });
                    }
                    else {
                        return detail;
                    }
                }) })));
        };
        this.handleMessageUpdate = () => {
            let changedMessages = this.state.orderErrorDetailsChanged.filter(message => {
                const changedData = message.data;
                const originalData = this.state.orderErrorDetailsOriginal.find(om => om.messageId === message.messageId)
                    .data;
                if (message.skip == true || originalData != changedData) {
                    return message;
                }
            });
            changedMessages.map(message => {
                let requestPath = "";
                if (message.skip == true) {
                    requestPath = "/sl3/rest/order_errors/message/mark_skipped/";
                }
                else {
                    requestPath = "/sl3/rest/order_errors/message/repair/";
                }
                fetch(requestPath, {
                    method: "post",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        message_id: message.messageId,
                        xml: message.data
                    })
                }).catch(console.log);
            });
            alert("Message update request sent to server");
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedOrderId: 0, selectedMessageId: 0, orderErrorDetailsOriginal: [], orderErrorDetailsChanged: [] })), () => {
                this.updateOrderMessages(this.state.selectedOrderId);
            });
        };
        this.updateOrderMessages = (orderId) => {
            fetch(`/sl3/rest/order_errors/all_messages/${orderId}`)
                .then(res => res.json())
                .then((serverOrderErrorDetails) => {
                const orderErrorDetails = serverOrderErrorDetails.map(message => ({
                    messageId: message.message_id,
                    userId: message.user_id,
                    userName: message.username,
                    data: message.data,
                    errorMessage: message.error_message,
                    causedError: message.caused_error,
                    func: message.func,
                    creationTimeStamp: message.creation_time_stamp
                }));
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { orderErrorDetailsOriginal: orderErrorDetails, orderErrorDetailsChanged: orderErrorDetails.map(details => (Object.assign(Object.assign({}, details), { skip: false }))) })));
            })
                .catch(console.log);
        };
        this.state = {
            selectedOrderId: this.props.orderId || 0,
            selectedMessageId: 0,
            orderIdsLocal: this.props.orderId ? [this.props.orderId] : [],
            orderErrorDetailsOriginal: [],
            orderErrorDetailsChanged: []
        };
    }
    render() {
        const messageDetails = this.state.orderErrorDetailsChanged.find(m => m.messageId == this.state.selectedMessageId);
        return (React.createElement(StyledErrorMessageDetailsScreen, { className: "error-message-details-screen-container" },
            React.createElement(ErrorMessageDetailsUpdate, { handleMessageUpdate: this.handleMessageUpdate }),
            React.createElement(ErrorMessageDetailsOrders, { orderIds: this.state.orderIdsLocal, handleOrderRowClick: this.handleOrderRowClick }),
            React.createElement(ErrorMessageDetailsMessages, { orderErrorDetails: this.state.orderErrorDetailsChanged, handleMessageRowClick: this.handleMessageRowClick, handleCheckboxChange: this.handleCheckboxChange }),
            React.createElement(ErrorMessageDetailsContent, { orderErrorDetails: messageDetails, handleDataChange: this.handleDataChange })));
    }
    componentDidMount() {
        if (!this.props.orderId) {
            fetch("/sl3/rest/order_errors/all_orders/")
                .then(res => res.json())
                .then(orderIds => {
                this.setState({
                    orderIdsLocal: orderIds
                });
            })
                .catch(console.log);
        }
        else {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { orderIdsLocal: [this.props.orderId], selectedOrderId: this.props.orderId })));
            this.updateOrderMessages(this.state.selectedOrderId);
        }
    }
}
export default ErrorMessageDetailsScreen;
